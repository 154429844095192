body {
  color: #374151;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.profile-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 400px);
}
.profile-grid  > div {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(1,1,1,.1);
  border-radius: 3px;
}

.roles-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 260px);
}

.roles-grid  > div {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(1,1,1,.1);
  border-radius: 3px;
}

.card-lg {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(1,1,1,.1);
  border-radius: 3px;
}

.course-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(260px);
}

.roles-detail-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(260px);
}
.roles-detail-grid > div {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(1,1,1,.1);
  border-radius: 3px;
}

.people-grid {
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 300px);
}
.people-grid > div {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(1,1,1,.1);
  border-radius: 3px;
}

.image-gallery {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}

.course-grid  > div {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(1,1,1,.1);
  border-radius: 3px;
  width: 500px;
}
@media print {
  @page { margin: 0; }
  body { margin: 1.6cm; }
}
@media screen and (min-width: 1240px) {
  .nav-left {
    flex: 1;
  }
}
@media screen and (max-width: 1240px) {
  .hide-mobile {
    display: none;
  }
}

#main-logo {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  vertical-align: middle;
}

body {
  font-family: Roboto;
}

.hero-text {
  font-size: 4rem;
}
