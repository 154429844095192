.navbar .subnav {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: -1;
}

.navbar .subnav.active {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease;
  height: 200px;
}

.navbar .navbar-middle-content ul.center-list {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
}

.navbar .navbar-drawer ul.center-list {
  margin-top: 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 25px;
}

.navbar .navbar-middle-content .center-list li a, .navbar .navbar-middle-content .center-list li span  {
  color: #232323;
  margin: 0px 15px;
  padding: 25px 0;
}

.navbar .navbar-drawer .center-list li {
  margin-bottom: 15px
}

.navbar .navbar-drawer .center-list li a, .navbar .navbar-drawer .center-list li span {
  color: white;
}

.navbar .center-list li a, .navbar .center-list li span {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}
.navbar {
  font-family: Poppins;
  background-color: #fff;
}
.subnav ul.subnav-content {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}
.subnav {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.subnav .subnav-content li {
  margin-top: 15px;
} 
.subnav .subnav-content a {
  color: #fff;
}

.navbar-drawer {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  z-index: 1;
  background-color: #0E7490;
  transition: right 0.4s ease;
}

.navbar-drawer.navbar-drawer-active {
  right: 0;
}

.navbar-drawer.navbar-drawer-inactive {
  right: -300px;
}
.navbar-menu-button {
  margin-top: 20px;
}



.navbar .right-nav {
  font-weight: 600;
  text-transform: uppercase;
}
